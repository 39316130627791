<template>
  <div class="spacer" :style="{ width: '100%', height: `calc(var(--mpadding) * ${num || 1})` }"></div>
</template>

<script>
export default {
  props: ["num"],
};
</script>

<style lang="scss" scoped>
.spacer {
  --mpadding: 1rem;
}
</style>
